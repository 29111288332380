.dynamicClientLogoMarquee {
  height: 160px;
  background: var(--color-background-inverted);
  border-top: 1px solid var(--brand-color-grey-9);
  border-bottom: 1px solid var(--brand-color-grey-9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientLogosStatic {
  height: 40px;
  width: 100%;
  margin-right: var(--layout-gutter-s);
  margin-left: var(--layout-gutter-s);
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.clientLogosMarquee {
  display: block;
}

.clientLogo {
  height: 40px !important;
  opacity: 1 !important;
}

@media (--large) {
  .clientLogosStatic {
    display: flex;
  }

  .clientLogosMarquee {
    display: none;
  }
}
