.overlay {
  display: grid;
  position: fixed;
  z-index: var(--z-index-overlay);
  inset: 0;
  overflow-y: auto;
  animation: overlayShow 150ms ease-in-out;
  background-color: rgb(0 0 0 / 55%);
  place-items: center;
}

.content {
  --dialog-spacing: var(--space-m-l);

  position: relative;
  width: 90vw;
  max-width: 750px;
  margin: var(--space-m-l) 0 var(--space-l-xl);
  padding: var(--dialog-spacing);
  animation: contentShow 150ms ease-in-out;
  border-radius: var(--border-radius-small);

  background-color: var(--color-background-default);
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--dialog-spacing);
  border: 0;
  background: transparent;
  color: var(--color-text-tertiary);
  cursor: pointer;
}

.closeButton:hover {
  color: var(--color-text-primary);
}

.closeIcon {
  display: block;
  width: 18px;
  height: auto;
  fill: currentcolor;
}

.title {
  margin: 0 0 var(--space-xs) 0;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-l);
}

.description {
  margin-bottom: var(--space-m);
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
  font-size: var(--text-body-l);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    transform: translate(0, -3%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}
