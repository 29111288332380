/* FADE IN */
.fade {
  transition: opacity var(--animation-duration, 0.5s) ease
    var(--animation-delay, 0s);
  opacity: var(--initial-opacity, 0);
}

[data-inview] .fade {
  opacity: var(--final-opacity, 1);
}

/* MOVE IN X */
.moveFadeInX {
  transform: translateX(var(--initial-offset, -40px));
  transition: transform var(--animation-duration, 0.5s) ease
      var(--animation-delay, 0s),
    opacity var(--animation-duration, 0.5s) ease var(--animation-delay, 0s);
  opacity: 0;
}

[data-inview] .moveFadeInX {
  transform: translateX(0);
  opacity: 1;
}

/* MOVE IN Y */
.moveFadeInY {
  transform: translateY(var(--initial-offset, -40px));
  transition: transform var(--animation-duration, 0.5s) ease
      var(--animation-delay, 0s),
    opacity var(--animation-duration, 0.5s) ease var(--animation-delay, 0s);
  opacity: 0;
}

[data-inview] .moveFadeInY {
  transform: translateY(0);
  opacity: 1;
}

/* HALO */
.halo {
  transform-origin: center;
  animation: halo var(--animation-duration, 2s) ease-in-out infinite alternate;
}

@keyframes halo {
  0% {
    transform: scale(var(--initial-scale, 0.5));
  }

  100% {
    transform: scale(var(--final-scale, 1));
  }
}

/* ROTATE */
.rotate {
  transform-origin: var(--animation-center, center);
  animation: rotate var(--animation-duration, 2s) linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* PULSE */
.pulse {
  animation: pulse var(--animation-duration, 2s) ease-in-out infinite alternate;
}

@keyframes pulse {
  0% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}
