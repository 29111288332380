.root {
  display: flex;
  flex-flow: row wrap;
  gap: var(--space-2xs) var(--space-xs);
}

.actionMinWidth {
  width: 100%;
}

@media (--small) {
  .actionMinWidth {
    width: unset;
    min-width: var(--button-min-width);
  }
}

@media (--medium) {
  .root {
    gap: var(--space-2xs) var(--space-m);
  }
}
