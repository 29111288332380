.root {
  display: flex;
  flex-direction: column;
}

.item {
  display: block;
  position: relative;
  width: 100%;
  margin-top: -1px;
  cursor: pointer;
}

.clickable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: var(--space-xs) var(--space-s);
  border: 1px solid var(--color-border);
  background: transparent;
}

.item:first-child .clickable {
  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}

.item:last-child .clickable {
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
}

.item:hover .clickable {
  z-index: 2;
  border-color: var(--color-accent-default);
}

.input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.input:checked + .clickable {
  z-index: 2;
  border-color: var(--color-accent-default);
  background: var(--color-accent-subdued);
  color: var(--color-accent-hover);
}

.label {
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
}

.subtitle {
  margin-top: 0.25em;
  color: var(--color-text-secondary);
  font-size: var(--text-body-xs);
  letter-spacing: 0.01em;
}

.item[aria-checked='true'] .label,
.item[aria-checked='true'] .subtitle {
  color: var(--color-accent-hover);
}

@media (--small) {
  .root {
    flex-direction: row;
  }

  .item {
    margin-top: 0;
    margin-left: -1px;
  }

  .item:first-child .clickable {
    border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
  }

  .item:last-child .clickable {
    border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
  }
}
