.root {
  --marquee-item-gap: var(--space-m);
  --marquee-speed: 1s;

  width: 100%;
  overflow-x: hidden;
}

/* Using a 3d transformation causes the browser to render the
 * animation using the GPU, making things run a bit smoother overall */
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes marqueeReversed {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

/* This is needed because of a weird marquee
   translation bug in Safari. See #41 */
.item,
.item img {
  transform: translate3d(0, 0, 0);
}

.itemWrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  animation: marquee var(--marquee-speed) linear infinite;
  animation-play-state: paused;

  & > * {
    width: fit-content;
    margin-left: var(--marquee-item-gap);
  }
}

.root.inView .itemWrapper {
  animation-play-state: running;
}

.root.reverse .itemWrapper {
  animation-name: marqueeReversed;
}

.root.paused .itemWrapper {
  animation-play-state: paused;
}

@media (--max) {
  .root {
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
  }
}
