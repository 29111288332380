.label {
  display: block;
  margin-bottom: 0.5em;
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.label[data-variant='obtrusive'] {
  color: var(--color-text-default);
}

.textarea,
.input {
  display: block;
  width: 100%;
  padding: var(--space-xs) var(--space-xs);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius-small);
  outline: none;
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  text-overflow: ellipsis;
}

.textarea {
  resize: none;
}

.textarea:focus,
.input:focus {
  border-color: var(--color-accent-default);
}

.textarea[aria-invalid],
.input[aria-invalid] {
  border-color: var(--color-input-border-alert);
}

.checkbox {
  accent-color: var(--color-accent-default);
}

.slider {
  --track-color: var(--base-color-grey-300);
  --track-focus-color: var(--base-color-grey-400);
  --thumb-color: var(--color-accent-default);
  --thumb-focus-color: var(--color-accent-hover);

  width: 100%;
  margin: 8px 0;
  background-color: transparent;
  appearance: none;
}

.slider:focus {
  outline: none;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  border: 0;
  border-radius: 9999px;
  background: var(--track-color);
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  width: 20px;
  height: 20px;
  margin-top: -8px;
  border-radius: 9999px;
  background: var(--thumb-color);
  cursor: pointer;
  appearance: none;
}

.slider:hover::-webkit-slider-thumb {
  background: var(--thumb-focus-color);
}

.slider:hover::-webkit-slider-runnable-track {
  background: var(--track-focus-color);
}

.slider::-moz-range-track {
  width: 100%;
  height: 4px;
  border: 0;
  border-radius: 9999px;
  background: var(--track-color);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 9999px;
  background: var(--thumb-color);
  cursor: pointer;
}

.slider::-ms-track {
  width: 100%;
  height: 4px;
  border-width: 9px 0;
  border-color: transparent;
  background: transparent;
  color: transparent;
  cursor: pointer;
}

.slider::-ms-fill-lower {
  border: 0;
  border-radius: 19998px;
  background: var(--track-color);
}

.slider::-ms-fill-upper {
  border: 0;
  border-radius: 19998px;
  background: var(--track-color);
}

.slider::-ms-thumb {
  width: 20px;
  height: 20px;
  margin-top: 0;
  border-radius: 9999px;
  background: var(--thumb-color);
  cursor: pointer;

  /* Needed to keep the Edge thumb centred */
}

.slider:hover::-ms-fill-lower {
  background: var(--track-focus-color);
}

.slider:hover::-ms-fill-upper {
  background: var(--track-focus-color);
}

.slider:hover::-ms-thumb {
  background: var(--thumb-focus-color);
}
