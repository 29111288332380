.root {
  --marker-notch-size: 0.5em;
  --marker-color: var(--section-marker-color, var(--color-teal));
  --marker-endpoint: 100%;
}

.root a:not([class]) {
  color: inherit;
  font: inherit;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

.root a:not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.root code:not([class]) {
  display: inline-block;
  padding: 0.2em 0.4em;
  border-radius: var(--border-radius-small);
  background: rgb(0 0 0 / 5%);
  color: inherit;
  font-family: var(--font-family-mono);
  font-size: 0.75em;
  vertical-align: baseline;
  white-space: nowrap;
}

.root p {
  max-width: 70ch;
}

.root p + p {
  margin-top: var(--space-s);
}

.root ul,
.root ol {
  margin-block: var(--space-s);
}

.root ul {
  padding-left: 0.2em;
  list-style: none;
}

.root ol {
  list-style-position: inside;
  padding-left: 0;
}

.root ul li,
.root ol li {
  position: relative;
  padding-left: 1.25em;
  margin-bottom: var(--space-s);
}

.root ul li::after {
  content: '';
  display: block;
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 0.4em;
  height: 0.4em;
  transform: rotate(45deg);
  clip-path: polygon(
    10% 0%,
    90% 0%,
    100% 10%,
    100% 90%,
    90% 100%,
    10% 100%,
    0% 90%,
    0% 10%
  );
}

.root > ul > li::after {
  background-color: var(--color-accent-default);
}

.root > ul ul li::after {
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: var(--color-text-tertiary);
}

.strongAsGradient em,
.strongAsGradient strong {
  position: relative;
  z-index: 2;
  font-style: normal;
  font-weight: inherit;
  white-space: nowrap;
}

.strongAsGradient strong::after,
.strongAsGradient em::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -0.075em;
  left: -0.5em;
  width: calc(100% + 0.75em);
  height: calc(100% + 0.25em);
  transform: scale(0, 1);
  transform-origin: 0 50%;

  transition: transform var(--transition-slow) 0.3s;
  background: linear-gradient(
    to right,
    transparent 0%,
    var(--marker-color) var(--marker-endpoint)
  );
  clip-path: polygon(
    0% var(--marker-notch-size),
    var(--marker-notch-size) 0%,
    calc(100% - var(--marker-notch-size)) 0%,
    100% var(--marker-notch-size),
    100% calc(100% - var(--marker-notch-size)),
    calc(100% - var(--marker-notch-size)) 100%,
    var(--marker-notch-size) 100%,
    0% calc(100% - var(--marker-notch-size))
  );
  will-change: transform;
}

.strongAsGradient.inView strong::after,
.strongAsGradient.inView em::after {
  transform: scale(1, 1);
}
