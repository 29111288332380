@media (--small) {
  .wide {
    max-width: var(--text-measure-wide);
  }

  .medium {
    max-width: var(--text-measure-medium);
  }

  .narrow {
    max-width: var(--text-measure-narrow);
  }

  .ultraNarrow {
    max-width: var(--text-measure-ultra-narrow);
  }

  /* More consistent measures over long form content e.g. article */
  .article {
    max-width: var(--long-form-article-width);
  }
}
