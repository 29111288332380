.root {
  display: block;
}

.labelWrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;

  & label {
    cursor: pointer;
  }

  /* TODO: Fix this tooltip alignment hack */
  & label + button {
    margin-bottom: 0.3em;
  }
}

.error,
.hint {
  margin-top: var(--space-2xs);
  font-size: var(--text-body-s);
}

.hint {
  color: var(--color-text-secondary);
}

.error {
  color: var(--color-text-alert);
}

.inlineLabelAndInput {
  display: flex;
  gap: var(--space-xs);
}

.sliderWrapper {
  display: flex;
  gap: var(--space-s-m);
  align-items: center;
  width: 100%;
}

.slider {
  width: 100%;
}

.sliderValue {
  font-feature-settings: 'tnum';
  min-width: 8ch;
  font-size: var(--text-body-l);
}

.checkboxLabel {
  display: block;
  margin-bottom: 0.5em;
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
  font-size: var(--text-body-s);
  letter-spacing: 0.0125em;
  cursor: pointer;
}

/* Toggle needs a tiny bit of optical compensation
 * from the label, to feel balanced.
 */
.toggle {
  margin-top: var(--space-2xs);
}
