.root {
  position: relative;
}

.intro {
  margin-bottom: var(--space-l-xl);
  color: var(--color-text-secondary);
  font-size: var(--text-body-l);
}

.summary {
  display: flex;
  align-items: flex-start;
  gap: var(--space-xs);
  margin-bottom: var(--space-m-l);
}

.summaryItem {
  flex: 0 1 100%;
  padding: var(--space-xs-s);
  border-radius: var(--border-radius-small);
  background: var(--color-background-light);
}

.summaryLabel {
  margin-bottom: 0.125em;
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.summaryValue {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-xs);
}

.inputs > * + * {
  margin-top: var(--space-s-m);
}

.actions {
  margin-top: var(--space-m-l);
}

.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: var(--space-m-l);
  border-radius: var(--border-radius-small);
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  line-height: 1.2;
}

.overlay[role='alert'] {
  background: var(--color-alert);
  color: var(--color-text-on-color);
}

.overlay:not([role='alert']) {
  background: var(--color-accent-default);
  color: var(--color-text-on-color);
}

.overlayIcon {
  color: currentcolor;
}

.overlayText {
  max-width: 30ch;
}

.overlayText > span {
  opacity: 0.65;
}

.overlayText > p + p {
  margin-top: 0.5em;
}

.fallbackLink {
  color: currentcolor;
  text-decoration-thickness: 1px;
}

.fallbackLink:hover {
  text-decoration: none;
}
