.root {
  transform-origin: center;
  animation: scaleAnimation 10s ease reverse infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  20% {
    transform: scale(1.2);
  }

  30% {
    transform: scale(0.8);
  }

  40% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.5);
  }

  60% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  80% {
    transform: scale(1.1);
  }

  90% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
