.root {
  --tick-list-item-color: var(--color-text-secondary);
  --tick-icon-color: var(--color-accent-default);

  max-width: 500px;
  margin: 0 0 calc(-1 * var(--space-2xs)) 0;
  padding: 0;

  list-style: none;
}

.listItem {
  display: flex;
  margin-bottom: var(--space-2xs);
  color: var(--tick-list-item-color);
}

.listItem > span {
  max-width: 45ch;
}

.tick {
  flex: 0 0 1em;
  margin-right: 4px;
  color: var(--tick-icon-color, var(--color-accent-default));
  font-size: 1.5em;
}

/* VARIANTS */
.root[data-variant='primary'] {
  --tick-list-item-color: var(--color-text-default);
}

.root[data-variant='unobtrusive'] {
  --tick-list-item-color: var(--color-text-secondary);
}
