.trigger {
  opacity: 0.8;
  cursor: pointer;
  all: unset;
}

.trigger:hover {
  opacity: 1;
}

.triggerIcon {
  width: 14px;
  height: 14px;
}

.tooltip {
  max-width: 200px;
  padding: 0.5em 0.75em;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 0.15em;
  background: var(--color-overlay);
  color: var(--color-text-on-color);
  font-size: var(--text-body-s);
  line-height: 1.25;
  user-select: none;
  will-change: transform, opacity;
}

.tooltip[data-state='delayed-open'] {
  animation-name: slideUpAndFade;
}

.arrow {
  color: var(--color-overlay);
}

@keyframes slideUpAndFade {
  from {
    transform: translateY(6px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
